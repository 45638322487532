<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha" style="vertical-align:middle !important">
          Registrar Alunos <span class="cabecario-turma2" v-if="this.ativa == 1"> Turma Habilitada </span>
        </h2>
      </div>

      <div class="col-md-5" >
        <pm-Button  type="button" @click="this.$router.push({name: 'coordenacao-turma-hab', params: { pg: this.pg } });"
          icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(0)"
          icon="pi pi-id-card"
          label="DISCIPLINAS" title="DISCIPLINAS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(1)"
          icon="pi pi-id-card"
          label="PROFESSOR" title="PROFESSOR"  class="p-button-sm p-button-info btn-color" style="margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </div>
    <div class="pm-card">
      <!-- <pm-PickList v-model="alunos_item"
             @move-to-target="moveToTarget" @move-all-to-target="moveAllToTarget" @move-to-source="moveToSource" @move-all-to-source="moveAllToSource"
             listStyle="height:342px" dataKey="id" id="alunturma">
              <template #sourceheader>
                  <div class="p-input-filled" style="margin-top:10px">
                    <pm-InputText placeholder="Buscar Alunos " type="text" style="width:350px;" v-model="aluno" @keyup="buscarAlunos"/>
                  </div>
              </template>
              <template #targetheader>
                  Alunos da Turma
              </template>

              <template #movecontrolsstart>

              </template>

              <template #item="slotProps">
                  <div >
                    <div >
                        <div>
                            <span class="">
                              <span class="badge badge-secondary2" style="margin-right:15px;">{{slotProps.item.matricula}}</span>
                               {{slotProps.item.nome_completo}}
                              <span style="margin-left:10px;" class="badge bg-danger table_style" :title="slotProps.item.qual_deficiencia" v-if="slotProps.item.deficiencia == 1">NEE</span>
                            </span>
                        </div>
                    </div>
                  </div>
              </template>
        </pm-PickList> -->
      <div class="row">
        <div class="col-md-6">
          <pm-DataTable :value="alunos" v-model:selection="alunoSelecionado" dataKey="id" class="p-datatable-customers"
            stripedRows scrollable :rowHover="true" selectionMode="multiple" @dblclick="addAluno" :metaKeySelection="false" :resizableColumns="true" scrollHeight="400px" :virtualScrollerOptions="{ itemSize: 46 }">
            <template #header>
              <div class="flex justify-content-center align-items-center" style="padding: 0px">
                <h5 class="m-0">Todos Alunos</h5>
                <span class="p-input-icon-left col-6" style="padding: 0px">
                  <i class="pi pi-search" />
                  <pm-InputText placeholder="Buscar Alunos " type="text" style="width:320px;" v-model="aluno" @keyup.enter="buscarAlunos"/>
                </span>
              </div>
            </template>

            <!-- <pm-Column selectionMode="multiple" style="flex: 0 0 3.5rem; "></pm-Column> -->

            <pm-Column field="matricula" header="Matricula" style="flex: 0 0 7.5rem">
              <template #body="alunos">
                <span class="badge bg-secondary">{{alunos.data.matricula}}</span>
              </template>
            </pm-Column>

            <pm-Column field="nome_completo"  header="Nome" style="min-width: 200px"></pm-Column>

          </pm-DataTable>
          <pm-Button v-if="alunoSelecionado" style="float:right;" icon="pi pi-plus" class="mt-2" label="Adicionar à Turma" @click="addAluno"></pm-Button>
        </div>

        <div class="col-md-6">
          <pm-DataTable :value="alunosTurma" v-model:selection="alunoSelecionado" dataKey="id" class="p-datatable-customers"
            scrollable scrollHeight="400px" selectionMode="single" @dblclick="deletarAluno" :metaKeySelection="false" :virtualScrollerOptions="{ itemSize: 46 }">
            <template #header>
              <div class="flex justify-content-center align-items-center" style="padding: 7px">
                <h5 class="m-0">Alunos na Turma</h5>
                <!-- <span class="p-input-icon-left col-6" hidden style="padding: 0px">
                    <i class="pi pi-search" />
                    <pm-InputText placeholder="Buscar Alunos " type="text" style="width:320px;" v-model="aluno2" @keyup.enter="buscarAlunos"/>
                  </span> -->
              </div>
            </template>

            <!-- <pm-Column selectionMode="single" style="flex: 0 0 3.5rem"></pm-Column> -->

            <pm-Column field="matricula" header="Matricula" style="flex: 0 0 7.5rem">
              <template #body="alunosTurma">
                <span class="badge bg-secondary">{{alunosTurma.data.matricula}}</span>
              </template>
            </pm-Column>

            <pm-Column field="nome_completo" header="Nome" style="min-width: '200px'"></pm-Column>


          </pm-DataTable>
          <pm-Button v-if="alunoSelecionado" style="float:right;" icon="pi pi-trash" label="Tirar da Turma" class="p-button-danger mt-2" @click="deletarAluno"></pm-Button>
        </div>

      </div>
    </div>

    <va-modal v-model="abrirModalRemover"  hide-default-actions >
      <template #header>
        <h2 style="color: #2196f3;">Remover Aluno na Turma</h2>
      </template>
      <slot>
        <p><b>Deseja remover o aluno(a) <span class="badge badge-secondary2" style="font-size: 15px!important;">{{nome_aluno}}</span> ? Selecione o motivo!</b></p>
        <div class="row" style="margin-bottom:20px;">
          <div class="col-md-12">
            <fieldset>
              <va-radio
                option="option0"
                v-model="radioSelectedOption"
                label="Transferência interna do aluno"
              />
              <br>
              <va-radio
                option="option1"
                v-model="radioSelectedOption"
                label="Saiu da escola (Transferido)"
              />
              <br>
              <va-radio
                option="option2"
                v-model="radioSelectedOption"
                label="Saiu da escola (Evadido)"
              />
              <br>
              <va-radio style="margin-top:10px;"
                option="option3"
                v-model="radioSelectedOption"
                label="Faleceu"
              />
              <br>
              <va-radio style="margin-top:10px;"
                option="option4"
                v-model="radioSelectedOption"
                label="Reclassificado"
              />
            </fieldset>
          </div>
          <!-- <div class="col-md-6" v-if="radioSelectedOption=='option0'">
                <div>
                  <div class="form-group col-md-12" >
                    <label style="margin-bottom:15px;">Selecione a turma para qual o aluno será transferido:</label>
                    <select class="form-control" v-model="alunoTurmaTransferida">
                      <option v-for="n in turmasRelacionadas" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>
                  <div class="col-md-8" style="margin-top:15px" v-if="alunoTurmaTransferida != 0">

                    <pm-Button  type="button" @click="realizarTranferenciaAluno()"
                      label="Realizar transferência" title="Realizar transferência"  class=" p-button btn-color">
                    </pm-Button>
                  </div>
                </div>
              </div> -->
        </div>

        <pm-Button  type="button"  @click="transferenciaPrimeiraEtapa"
          label="Realizar envio de dados para transferência" title="Realizar ação"  class=" p-button btn-color">
        </pm-Button>
      </slot>
    </va-modal>
  </section>
</template>

<script>

import { Aluno } from "@/class/alunos";
import axios from 'axios'
import { defineComponent } from "vue";
import { Transferecia } from "@/class/transferencia.js";
import { Turma } from "@/class/turma.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";

export default defineComponent({
  props: {
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
    turno:{},
    ativa:{},
    //Se for 0, nao faz nada. Se for 1 adicionar apenas disciplinas e se for 2 adicionar apenas alunos
    tipoDeAcao: {
      default: 0,
    },
    erroturmaeditar:{},
    pg:{}
  },
  data() {
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Turmas'},
      ],
      radioSelectedOption: "opcao1",
      abrirModalRemover:false,
      tipo_segmento: null,

      ano:null,
      aluno:null,
      alunos:[],
      alunosTurma:[],
      alunos_item: [],

      nome_aluno:null,
      aluno_id:null,
      alunoTurmaTransferida:0,
      turmasRelacionadas:[],

      alunoSelecionado: null,
      situacaoAno: 0
    };
  },

  methods: {
    getMotivo() {
        let motivo = "";
        if (this.radioSelectedOption == "option0") {
          motivo = "Transferido Internamente";
        }
        if (this.radioSelectedOption == "option1") {
          motivo = "Transferido Externamente";
        }
        else if (this.radioSelectedOption == "option2") {
          motivo = "Evadido";
        }
        else if (this.radioSelectedOption == "option3") {
          motivo = "Falecido";
        }
        else if (this.radioSelectedOption == "option4") {
          motivo = "Reclassificado";
        }
        else if (this.radioSelectedOption == "option5") {
          motivo = "Entrou na turma errada";
        }
        return motivo;
    },

    transferenciaPrimeiraEtapa() {
      const dados = {
          aluno_id: this.alunoSelecionado.id,
          turma_inicial: this.turma_id,
          tipo_evento: this.getMotivo()
      }
      this.carregandoPagina = true;
      Transferecia.store(dados).then((resp) => {
      this.$vaToast.init({
            message: resp.data.message,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
        setTimeout(() => {
          if (window.confirm("Você deseja finalizar a transferência do aluno agora?")) {
            this.$router.push({name: 'coordenador/transferencia'});
          }
        }, 600);
      }).catch((err) => {
        this.$vaToast.init({
            message: err.response.data.error,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }).finally(() => {
        this.carregandoPagina = false;
        this.abrirModalRemover = !this.abrirModalRemover;
        this.radioSelectedOption = 'option0';
      });
    },

    // async removerAlunoMotivo() {
    //   try {
    //     let motivo = "";
    //     if (this.radioSelectedOption == "option1") {
    //       motivo = "Transferido";
    //     }
    //     if (this.radioSelectedOption == "option2") {
    //       motivo = "Evadido";
    //     }
    //     else if (this.radioSelectedOption == "option3") {
    //       motivo = "Faleceu";
    //     }
    //     let novo = {
    //       motivo: motivo,
    //       turma_id: this.turma_id,
    //       // aluno_id: this.aluno_id,
    //       aluno_id: this.alunoSelecionado.id,
    //     }
    //     let data = await Aluno.RemoverAlunoTurma(novo);
    //     // this.alunos_item = [];
    //     // this.alunosTurma = [];
    //     this.alunoSelecionado = false;
    //     await this.buscarAlunoNaTurma(0);

    //     this.aluno_id = null;
    //     this.abrirModalRemover = !this.abrirModalRemover;
    //     this.$vaToast.init({
    //         message: "Aluno removido com sucesso!",
    //         iconClass: 'fa-star-o',
    //         position: 'top-right',
    //         duration: 3000,
    //         color: 'success',
    //         fullWidth: false,
    //     });
    //   }
    //   catch(e) {
    //     this.$vaToast.init({
    //         message: "Problema ao remover o aluno!",
    //         iconClass: 'fa-star-o',
    //         position: 'top-right',
    //         duration: 3000,
    //         color: 'warning',
    //         fullWidth: false,
    //     });
    //   }
    // },
    irOutraPg(pg){
      if(pg == 0){
       this.$router.push({name: 'turma-add-disciplina-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 1){
       this.$router.push({name: 'turma-add-professor-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 2){
       this.$router.push({name: 'turma-add-aluno-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
    },
    async buscarAlunos(event) {
      if(this.aluno == null){
        this.buscarAlunoNaTurma(0);
      }
      const data =  await axios.get('/alunos/v2/'+this.aluno+'/'+this.ano);
      this.alunos = [];
      this.alunos = data.data;

      this.alunos_item = [];
      this.buscarAlunoNaTurma(1);
    },

    async buscarAlunoNaTurma(n){
      try {
        if(n==0){
          await this.alunosQNaoSaoDaTurma();
        }
        const data = await Turma.obtemAlunosTurma(this.turma_id);

        //pegar os alunos apenas que estejam 'Normal'
        this.alunosTurma = data.data.filter(obj => obj.status == "Normal" || obj.status == "Semestre 1 Encerrado");

        this.alunos_item.push(this.alunos);
        this.alunos_item.push(this.alunosTurma);
      } catch (e) {

      }
    },

    async alunosQNaoSaoDaTurma(){
      try {
        const data = await Aluno.obtemTodos2();
        // this.alunos = [];
        this.alunos = data.data;
      } catch (e) {

      }
    },

    async identificarTipoSegmento(){
      const data = await SegmentoEscolar.obtemUm(this.etapa_id);
      this.tipo_segmento = data.data.tipo;
    },

    async turmaConfig(){
       const turma = {
        turma_id: this.turma_id,
        etapa_id: this.etapa_id,
        periodo_id: this.periodo_id,
        escola_id: this.escola_id,
      }

      const resp_turma = await Turma.aturma(turma);
      const  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
      ];

      this.items_bread = tt;
    },

    async addAluno(payload){
      if(this.alunoSelecionado == null){
        this.$vaToast.init({
          message: "Selecione um aluno",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: 'warning',
          fullWidth: false,
        });
      }else{
        let aluno;
        for (let index = 0; index < this.alunoSelecionado.length; index++) {
          aluno = {
            ano: this.ano,
            turma_id: this.turma_id,
            aluno_id: this.alunoSelecionado[index].id,
          }
          // aluno.aluno_id.push(this.alunoSelecionado[index].id);
          // aluno.ano = this.ano;
          // aluno.turma_id = this.turma_id;
        const data = await Aluno.addAlunoTurma(aluno);
        }
        this.alunosQNaoSaoDaTurma()
        this.buscarAlunoNaTurma(1);
        this.alunoSelecionado = false;
        // setTimeout(function() {
        //   this.buscarAlunoNaTurma(0);
        // }, 1000);
      }
    },

    async deletarAluno(event){
      if(this.alunoSelecionado == null){
        this.$vaToast.init({
          message: "Selecione um aluno",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: 'warning',
          fullWidth: false,
        });
      }else{
        this.carregandoPagina = true;
        const payload = {
          ano: this.ano,
          aluno_id: this.alunoSelecionado.id,
          turma_id: this.turma_id,
        };
        const dt = await Aluno.deletarAlunoTurmaV2(payload);
        // console.log('alo',dt)
        if(dt.data == "Turma Ativa"){
          this.abrirModalRemover = true;
          this.alunos = [];
          await this.buscarAlunoNaTurma(0);
          this.nome_aluno = this.alunoSelecionado.nome_completo;
          await this.buscaTurmasRelacionadas();
        }
        this.carregandoPagina = false;
        await this.buscarAlunoNaTurma(0);
        // await this.buscarAlunoNaTurma(0);
      }
    },

    // moveToTarget(event){
    //   let payload = {
    //     ano: this.ano,
    //     aluno_id: event.items[0].id,
    //     turma_id: this.turma_id,
    //   };
    //   this.addAluno(payload);
    // },

    // moveToSource(event){
    //   let payload = {
    //     ano: this.ano,
    //     aluno_id: event.items[0].id,
    //     turma_id: this.turma_id,
    //   };
    //   this.aluno_id = event.items[0].id;
    //   this.deletarAluno(payload,event);
    // },



    async realizarTranferenciaAluno() {
      try {
        const novo = {
          turma_id: this.turma_id,
          turma_id_nova: this.alunoTurmaTransferida,
          aluno_id: this.alunoSelecionado.id,
        }
        const data = await Aluno.TransferenciaInternaAluno(novo);
        this.alunoTurmaTransferida = 0;

        this.abrirModalRemover = !this.abrirModalRemover;

        this.alunos_item = [];
        this.alunoSelecionado = false;
        await this.buscarAlunoNaTurma(0);
        this.$vaToast.init({
            message: "Aluno transferido com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
      }
      catch(e) {
        this.$vaToast.init({
            message: "Problema ao transferir aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },

    async buscaTurmasRelacionadas() {
      const novo = {
        ano: this.ano,
        escola_id:this.escola_id,
        segmento_id: this.etapa_id,
      }

      const data = await Turma.showEscolaSegmentoTurma(novo);
      this.turmasRelacionadas = [];
      for (const el of data.data) {
        //nao mostra a turma que ele atualmente está
        if (el.id == this.turma_id)
          {continue;}
        this.turmasRelacionadas.push(el);
      }
    },
  },
  async beforeMount(){
    try {
      if(this.turma_id != null){
        this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
        this.ano = parseInt(sessionStorage.getItem("anoSelecionado"));

        await this.turmaConfig();
        await this.identificarTipoSegmento();
        await this.buscarAlunoNaTurma(0);
      }else{
        this.$router.push({ name: "turmas"});
      }
    } catch (e) {
      // console.log(e)
    }
  }
});
</script>

<style>
  #alunturma .p-picklist-source-controls{
   display:none;
 }
 #alunturma .p-picklist-target-controls{
   display:none;
 }

 #alunturma .p-picklist-transfer-buttons .p-button:nth-child(2) {
    display:none;
 }

 #alunturma .p-picklist-transfer-buttons .p-button:nth-child(4) {
    display:none;
 }

 .badge-secondary2 {
    color: #fff;
    background-color: #6c757d;
    font-size: 9px;
 }

 .cabecario-turma2 {
    background-color: rgb(59, 130, 246);
    color: #fff;
    margin-left: 15px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 13px;
    vertical-align:middle !important
}
</style>
